import { BASE_URL } from 'config/env';
import { ready } from 'global-utils/doc-state';

const getScript = (
  source: string,
  id: string,
  userLocation?: string,
  userContinent?: string
) => {
  const existingScript = document.getElementById(id);

  if (!existingScript) {
    ready(() => {
      const script = document.createElement('script');
      const cgbScripts = document.querySelectorAll('[id^="cbg"]');
      const lastCbgScript = cgbScripts[cgbScripts.length - 1];

      if (id) {
        script.setAttribute('id', id);
      }

      // eslint-disable-next-line func-names
      script.onerror = function () {
        console.error(`Error loading ${this.src}`);
      };

      if (userLocation) {
        script.setAttribute('location', userLocation);
      }

      if (userContinent) {
        script.setAttribute('continent', userContinent);
      }

      script.src = source;
      lastCbgScript.after(script);
    });
  } else {
    console.warn(`script ${id} is already on page`);
  }
};

export const initCConsent = (userLocation: string, userContinent: string) => {
  getScript(
    `${BASE_URL}cbgCConsent.js`,
    'cbgCConsent',
    userLocation,
    userContinent
  );
};

const useLegacyGatewayDomains = [
  'www.lbconline.la',
  'www.svyturyswebshop.lt',
  'www.aldaraveikals.lv',
  'www.sakuwebshop.ee',
  'cadi.carlsberggroup.com',
  'signup.carlsbergmarstons.co.uk',
  'onboard.carlsberggroup.com',
  'blivkunde.carlsbergdanmark.dk',
  'signup.carlsbergsverige.se',
  'blikunde.ringnes.no'

  // Malasya Cs+ > no governance
  // 'www.mycarlsbergonline.my',

  // cs test domains
  /*  'cs-sxp-tst-cd-uk.carlsberggroup.com',
  'cs-sxp-tst-cd-uk-slot.carlsberggroup.com',
  'cs-sxp-tst-cd-dk-slot.carlsberggroup.com',
  'cs-sxp-tst-cd-se-slot.carlsberggroup.com',
  'cs-sxp-tst-cd-no-slot.carlsberggroup.com',
  'cs-sxp-tst-cd-ch-slot.carlsberggroup.com',
  'cs-sxp-tst-cd-fi-slot.carlsberggroup.com',
  'cs-sxp-tst-cd-dk.carlsberggroup.com',
  'cs-sxp-tst-cd-ch.carlsberggroup.com',
  'cs-sxp-tst-cd-fi.carlsberggroup.com',
  'cs-sxp-tst-cd-no.carlsberggroup.com',
  'cs-sxp-tst-cd-se.carlsberggroup.com', */

  // cs uat domains
  /* 'cs-sxp-uat-cd-uk.carlsberggroup.com',
  'cs-sxp-uat-cd-dk.carlsberggroup.com',
  'cs-sxp-uat-cd-ch.carlsberggroup.com',
  'cs-sxp-uat-cd-no.carlsberggroup.com',
  'cs-sxp-uat-cd-fi.carlsberggroup.com',
  'cs-sxp-uat-cd-se.carlsberggroup.com',
  'cs-sxp-uat-cd-dk-slot.carlsberggroup.com',
  'cs-sxp-uat-cd-uk-slot.carlsberggroup.com',
  'cs-sxp-uat-cd-se-slot.carlsberggroup.com',
  'cs-sxp-uat-cd-no-slot.carlsberggroup.com',
  'cs-sxp-uat-cd-ch-slot.carlsberggroup.com',
  'cs-sxp-uat-cd-fi-slot.carlsberggroup.com',
  'order.carlsbergmarstons.co.uk',

 */

  // cs prd domains
  /* 'cs-sxp-prd-cd-ch-slot.carlsberggroup.com',
  'cs-sxp-prd-cd-ch.carlsberggroup.com',
  'cs-sxp-prd-cd-dk-slot.carlsberggroup.com',
  'cs-sxp-prd-cd-dk.carlsberggroup.com',
  'cs-sxp-prd-cd-fi.carlsberggroup.com',
  'cs-sxp-prd-cd-fi-slot.carlsberggroup.com',
  'cs-sxp-prd-cd-no.carlsberggroup.com',
  'cs-sxp-prd-cd-no-slot.carlsberggroup.com',
  'cs-sxp-prd-cd-se.carlsberggroup.com',
  'cs-sxp-prd-cd-se-slot.carlsberggroup.com',
  'cs-sxp-prd-cd-uk.carlsberggroup.com',
  'cs-sxp-prd-cd-uk-slot.carlsberggroup.com',
  'shop.carlsbergdanmark.dk',
  'shop.ringnes.no',
  'shop.sinebrychoff.fi',
  'carlsbergonline.se',
  'shop.feldschloesschen.swiss'
*/

  // csplus dev domains
  /*
  csplus-laos.dev.cx-apps.io',
  'csplus-lithuania.dev.cx-apps.io',
  'csplus-latvia.dev.cx-apps.io',
  'csplus-estonia.dev.cx-apps.io',
  */

  // csplus tst domains

  /* 'csplus-laos.tst.cx-apps.io', 
  'csplus-lithuania.tst.cx-apps.io',
  'csplus-latvia.tst.cx-apps.io',
  'csplus-estonia.tst.cx-apps.io',
  */

  // csplus stg domains

  /* 
  'csplus-laos.stg.cx-apps.io',
  'csplus-lithuania.stg.cx-apps.io',
  'csplus-latvia.stg.cx-apps.io',
  'csplus-estonia.stg.cx-apps.io' */
];

export const initAGateway = (userLocation: string) => {
  const currentDomain = window.location.host;
  // we need to make sure that the isLegacyDomain is not sensitive to the www. subdomain
  const normalizedDomain = currentDomain.replace(/^www\./, '');
  const parsedDomainsList = useLegacyGatewayDomains.map((domain) =>
    domain.replace(/^www\./, '')
  );
  const isLegacyDomain = parsedDomainsList.includes(normalizedDomain);
  // render the legacy AG for domains on the legacy list and the new for the rest
  const scriptSrc = isLegacyDomain
    ? `${BASE_URL}cbgAGateway.js`
    : `${BASE_URL}cbgAGateway_v2.js`;
  getScript(scriptSrc, 'cbgAGateway', userLocation);
};
